export const getOverviewData = () => {
  return [
    {
      sectionImage: "Overview/shift4_logo.svg",
      sectionTitle: "Shift4",
      paragraph:
        "Ideal for intimate meetings and receptions, this sleek event space features turnkey technology and unique art pieces created exclusively for Allegiant Stadium.",
      // area: '44,500',
      reception: "120",
      banquet: "100",
    },
  ];
};

export const getCarouselImages = () => {
  return [
    "Shift4/Photos/shift4-01.jpg",
    "Shift4/Photos/shift4-02.jpg",
    "Shift4/Photos/shift4-03.jpg",
    "Shift4/Photos/shift4-04.jpg",
  ];
};

export const getLayoutImages = () => {
  return ["Shift4/Layout/layout-yamaava.jpg"];
};
